

import mcgill from '../imgs/mcgill.png';
import dawson from '../imgs/dawson.jpg'
import eim from '../imgs/eim.png'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
    Typography, Box, Grid, Accordion, AccordionSummary, AccordionDetails
} from '@mui/material'

export default function Education () {


    return(

        <Grid container spacing={3} justifyContent='center'>

            <Grid data-aos="fade-left" item xs={12} sx={{textAlign: 'center', color: 'white', mb: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>

                <Typography variant="h4"  sx={{fontFamily: 'Anton, sans-serif', my: 3}}>
                    Education
                </Typography>
                <hr className='line'/>
            
            </Grid>


            <Grid data-aos="fade-left" item xs={12} md={6} lg={4} sx={{textAlign: 'center', color: 'white'}} display='flex' alignItems='center' flexDirection='column' justifyContent='center'>
                <Box sx={{backgroundColor: 'black', maxWidth: 365, minHeight: 300, p: 5, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <img key="McGillLogo" src={mcgill} alt="McGill University Logo" style={{maxHeight: 60}} />
                    <Typography fontWeight={600} sx={{mt: 2}}>
                        McGill University
                        <br/>
                        Bachelor of Commerce
                        <br/>
                    </Typography>
                    <Typography>
                        Information Systems
                    </Typography>
                    <Typography>
                        Computer Science
                    </Typography>     
                    <Typography>
                        Retail Management
                    </Typography>
                    <Typography>
                        September 2019 - Present
                    </Typography>

                <Accordion sx={{backgroundColor: 'black', color: 'white', mt: 3}}>
                    <AccordionSummary
                        sx={{textAlign: 'center'}}
                        expandIcon={<ExpandMoreIcon sx={{color: 'white'}} />}
                        >
                        <Typography sx={{fontWeight: 800, textAlign: 'center'}}>Click to See Relevant Courses</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Box sx={{backgroundColor: 'black', color: 'white'}}>
                        
                            <Typography fontSize='0.8rem' textAlign='left'>
                                COMP202: Foundations of Programming
                                <br/>
                                MGCR331: Information Systems
                                <br/>
                                INSY333: Systems Analysis & Modelling
                                <br/>
                                INSY442: Business Intelligence & Data Analytics
                                <br/>
                                INSY341: Developing Business Apps
                                <br/>
                                COMP250: Intro to Computer Science
                                <br/>
                                COMP251: Algorithms and Data Structures
                                <br/>
                                COMP206: Intro to Software Systems
                                <br/>
                                INSY336: Data Handling & Coding for Analytics
                                <br/>
                                INSY437: Managing Data & Databases
                                <br/>
                                COMP303: Software Design
                                <br/>
                                COMP307: Principles of Web Development
                                <br/>
                                INSY446: Data Mining for Business Analytics
                                <br/>
                                MGSC416: Data-Driven Models for Operations Analytics
                            </Typography>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                
                </Box>
            </Grid>

            <Grid data-aos="fade-left" item xs={12} md={6} lg={4} sx={{textAlign: 'center', color: 'white'}} display='flex' alignItems='center' flexDirection='column' justifyContent='center'>
                <Box sx={{backgroundColor: 'black', maxWidth: 365, minHeight: 300, p: 5, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <img key="DawsonLogo" src={dawson} alt="Dawson College Logo" style={{maxHeight: 60}} />
                    <Typography fontWeight={600} sx={{mt: 2}}>
                        Dawson College (CEGEP)
                        <br/>
                        Diploma of College Studies (DEC)
                        <br/>
                    </Typography>
                    <Typography>
                        International Business Studies
                    </Typography>
                    <Typography>
                        Graduated with First Class Honours
                    </Typography>     
                    <Typography>
                        September 2017 - May 2019
                    </Typography>

                <Accordion sx={{backgroundColor: 'black', color: 'white', mt: 3}}>
                    <AccordionSummary
                        sx={{textAlign: 'center'}}
                        expandIcon={<ExpandMoreIcon sx={{color: 'white'}} />}
                        >
                        <Typography sx={{fontWeight: 800, textAlign: 'center'}}>Click to See Relevant Courses</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Box sx={{backgroundColor: 'black', color: 'white'}}>
                            <Typography fontSize='0.8rem' textAlign='left'>
                                Calculus I
                                <br/>
                                Linear Algebra
                                <br/>
                                Calculus II
                                <br/>
                                Research Methods
                                <br/>
                                Quantitative Methods
                                <br/>
                                Intro to Computer Science
                            </Typography>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                </Box>
            </Grid>


            <Grid data-aos="fade-left" item xs={12} md={6} lg={4} sx={{textAlign: 'center', color: 'white'}} display='flex' alignItems='center' flexDirection='column' justifyContent='center'>
                <Box sx={{backgroundColor: 'black', maxWidth: 365, minHeight: 300, p: 5, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <img key="DawsonLogo" src={eim} alt="Dawson College Logo" style={{maxHeight: 60}} />
                    <Typography fontWeight={600} sx={{mt: 2}}>
                        École internationale de Montréal
                        <br/>
                        High School Diploma
                        <br/>
                    </Typography>
                    <Typography>
                        SÉBIQ
                    </Typography> 
                    <Typography>
                        International Baccalaureate Program
                    </Typography>   
                    <Typography>
                        September 2012 - June 2017
                    </Typography>

                <Accordion sx={{backgroundColor: 'black', color: 'white', mt: 3}}>
                    <AccordionSummary
                        sx={{textAlign: 'center'}}
                        expandIcon={<ExpandMoreIcon sx={{color: 'white'}} />}
                        >
                        <Typography sx={{fontWeight: 800, textAlign: 'center'}}>Click to See Achievements</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Box sx={{backgroundColor: 'black', color: 'white'}}>
                            <Typography fontSize='0.8rem' textAlign='left'>
                                <b>Member of the Student Council</b>
                                <br/>
                                <b>Best IB Personal Project, second place (2017)</b>: Selected among all students in Secondary 5 (Grade 11) in Montreal enrolled in a school with the International Baccalaureate program. My research project focused specifically on tax havens and the strategies used by corporations to reduce their taxes.
                                <br/>
                                <b>Prix du Mérite en histoire (2017)</b>: Awarded by the Mouvement national des Québécois & Québécoises for obtaining a grade of over 95% in History in Secondary 4 (Grade 10).  

                            </Typography>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                </Box>
            </Grid>

        </Grid>

    )
}
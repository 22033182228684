

import React, {Fragment} from 'react'

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import GitHubIcon from '@mui/icons-material/GitHub';

import Carousel from 'react-material-ui-carousel'

let HackCarousel = (props) => {
    

    return (

 
        <Grid data-aos="fade-up" item xs={12} sm={10} md={6} lg={4} sx={{m: 2}}>
            <Card>
                <CardHeader
                    title={
                        <Typography variant="h5" sx={{ml: 1, p: 1, fontFamily: 'Anton, sans-serif'}}>
                           {props.title}
                        </Typography>
                    }
                />

                <Carousel className='centered'
                autoPlay={false}
                navButtonsAlwaysVisible={true}
                style={{minHeight: 400}}
                
            >
                {
                    props.pics.map((item, i)=> (
                        <Fragment key={i}>
                            <CardMedia
                            component='img'
                            src={item}
                            alt="Image" 
                            sx={{width: '80%', textAlign: 'center', mx: 'auto', minHeight: 600}}
                            />
                        </Fragment>
                        
                    ))

                }
            </Carousel>
            <CardContent>
                <Typography variant="h6" sx={{ml: 1}}>
                    {props.description}
                </Typography>
            </CardContent>
            <CardActions>
                <Link href={props.github} target="_blank">
                    <Tooltip title={props.github} placement="bottom" arrow >
                        <GitHubIcon  sx={{ml: 2, mb: 1, color: 'black'}} fontSize={"large"} />  
                    </Tooltip>
                </Link>
            </CardActions>
            </Card>
        </Grid>
    )
}

export default HackCarousel;
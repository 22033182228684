


import React from 'react'

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography'
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import GitHubIcon from '@mui/icons-material/GitHub';


let HackDesSite = (props) => {


    return (
        <Card data-aos="fade-up" sx={{mb: 10}}>
            <CardHeader
                title={
                    <Typography variant="h5" sx={{ml: 1, p: 1, fontFamily: 'Anton, sans-serif'}}>
                        {props.title}
                    </Typography>
                }
            />
        <CardContent>
            <Typography variant="h6" sx={{ml: 2}}>
                {props.description}
            </Typography>
        </CardContent>
        <CardActions>
            <Link href={props.github} target="_blank">
                <Tooltip title={props.github} placement="bottom" arrow >
                    <GitHubIcon  sx={{ml: 3, mb: 1, color: 'black'}} fontSize={"large"} />  
                </Tooltip>
            </Link>
            <Link href={props.site} target="_blank">
                <Tooltip title={props.site} placement="bottom" arrow >
                    <OpenInBrowserIcon sx={{ml: 2, mb: 1, color: 'black'}} fontSize={"large"} />  
                </Tooltip>
            </Link>
        </CardActions>
        </Card>
    )
}

export default HackDesSite;

import {
    Fab, Fade, Box, useScrollTrigger
} from '@mui/material'

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function ScrollToTop () {

    return (
        <ScrollTop>
            <Fab size="medium" aria-label="scroll back to top" sx={{zIndex: 100, position: 'relative'}}>
                <KeyboardArrowUpIcon />
            </Fab>
        </ScrollTop>
    )
}



function ScrollTop(props) {
    const { children } = props;

    const trigger = useScrollTrigger({
      target: undefined,
      disableHysteresis: true,
      threshold: 100,
    });
  
    const handleClick = () => {
      const anchor = document.querySelector('#top');
  
      if (anchor) {
        anchor.scrollIntoView({
          block: 'start',
          behavior: "smooth",
        });
      }
    };
  
    return (
      <Fade in={trigger}>
        <Box
          onClick={handleClick}
          role="presentation"
          sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 200 }}
        >
          {children}
        </Box>
      </Fade>
    );
  }
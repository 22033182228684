
import { Grid, Typography} from "@mui/material";


export default function Skills () {


    return (
        <Grid  container spacing={3} justifyContent='center' alignItems='center' sx={{mt: 10, backgroundColor: '#aaaa', color: 'white', pb: 5}}>

            <Grid data-aos="fade-up" item xs={12} sx={{textAlign: 'center', color: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <Typography variant="h4"  sx={{fontFamily: 'Anton, sans-serif', my: 3}}>
                    Skills
                </Typography>
                <hr className='line'/> 
            </Grid>


            <Grid data-aos="fade-up" item xs={12} md={6} lg={4} xl={3} sx={{textAlign: 'center', color: 'white'}} display='flex' alignItems='center' flexDirection='column' justifyContent='center'>
                <Typography sx={{fontWeight: 800, textTransform: 'uppercase', fontSize: '1.2rem'}}>
                    Programming Languages
                </Typography>
         
                <Grid container display='flex' justifyContent='center' sx={{textAlign: 'center', mt: 2}}>
                    {
                        ['Javascript', 'Python', 'Java', 'C', 'Bash'].map((element, i) => (
                            <Grid key={i} item sx={{m: 1}}>
                                <Typography sx={{mx: 2, fontWeight: 700, border: 'solid 1px', p: 2, borderRadius: 2, backgroundColor: '#aa12'}}>
                                    {element}
                                </Typography>
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>


            <Grid data-aos="fade-up" item xs={12} md={6} lg={4} xl={3}  sx={{textAlign: 'center', color: 'white'}} display='flex' alignItems='center' flexDirection='column' justifyContent='center'>
                <Typography sx={{fontWeight: 800, textTransform: 'uppercase', fontSize: '1.2rem'}}>
                    Frontend Development
                </Typography>
         
                <Grid container display='flex' justifyContent='center' sx={{textAlign: 'center', mt: 2}}>


                    {
                        ['Javscript ES6', 'Typescript', 'React', 'HTML', 'CSS', 'UI/UX Design', 'Bootstrap', 'Material UI', 'Redux'].map((element, i) => (
                            <Grid key={i} item sx={{m: 1}}>

                                <Typography sx={{mx: 2, fontWeight: 700, border: 'solid 1px', p: 2, borderRadius: 2,  backgroundColor: '#aaaaab'}}>
                                    {element}
                                </Typography>
                            </Grid>
                        ))
                    }

                </Grid>
            </Grid>


            <Grid data-aos="fade-up" item xs={12} md={6} lg={4} xl={3}  sx={{textAlign: 'center', color: 'white'}} display='flex' alignItems='center' flexDirection='column' justifyContent='center'>
                <Typography sx={{fontWeight: 800, textTransform: 'uppercase', fontSize: '1.2rem'}}>
                    Backend Development
                </Typography>
         
                <Grid container display='flex' justifyContent='center' sx={{textAlign: 'center', mt: 2}}>

                    {
                        ['Node.js', 'Express.js', 'SQL', 'MongoDB', 'Stripe', 'Firebase'].map((element, i) => (
                            <Grid key={i} item sx={{m: 1}}>
                                <Typography sx={{mx: 2, fontWeight: 700, border: 'solid 1px', p: 2, borderRadius: 2,  backgroundColor: '#aaaa32'}}>
                                    {element}
                                </Typography>
                            </Grid>
                        ))
                    }

                </Grid>
            </Grid>

            <Grid data-aos="fade-up" item xs={12} md={6} lg={4} xl={3}  sx={{textAlign: 'center', color: 'white'}} display='flex' alignItems='center' flexDirection='column' justifyContent='center'>
                <Typography sx={{fontWeight: 800, textTransform: 'uppercase', fontSize: '1.2rem'}}>
                    Data Science
                </Typography>
         
                <Grid container display='flex' justifyContent='center' sx={{textAlign: 'center', mt: 2}}>

                    {
                        ['Pandas', 'Jupyter', 'scikit-learn'].map((element, i) => (
                            <Grid key={i} item sx={{m: 1}}>
                                <Typography sx={{mx: 2, fontWeight: 700, border: 'solid 1px', p: 2, borderRadius: 2,  backgroundColor: '#4aaa'}}>
                                    {element}
                                </Typography>
                            </Grid>
                        ))
                    }

                </Grid>
            </Grid>

            <Grid data-aos="fade-up" item xs={12} md={6} lg={4} xl={3}  sx={{textAlign: 'center', color: 'white'}} display='flex' alignItems='center' flexDirection='column' justifyContent='center'>
                <Typography sx={{fontWeight: 800, textTransform: 'uppercase', fontSize: '1.2rem'}}>
                    Mobile Development
                </Typography>
         
                <Grid container display='flex' justifyContent='center' sx={{textAlign: 'center', mt: 2}}>

                    {
                        ['React Native'].map((element, i) => (
                            <Grid key={i} item sx={{m: 1}}>
                                <Typography sx={{mx: 2, fontWeight: 700, border: 'solid 1px', p: 2, borderRadius: 2,  backgroundColor: '#aa55'}}>
                                    {element}
                                </Typography>
                            </Grid>
                        ))
                    }

                </Grid>
            </Grid>


            <Grid data-aos="fade-up" item xs={12} md={6} lg={4} xl={3}  sx={{textAlign: 'center', color: 'white'}} display='flex' alignItems='center' flexDirection='column' justifyContent='center'>
                <Typography sx={{fontWeight: 800, textTransform: 'uppercase', fontSize: '1.2rem'}}>
                    Software Tools
                </Typography>
         
                <Grid container display='flex' justifyContent='center' sx={{textAlign: 'center', mt: 2}}>

                    {
                        ['Git', 'GitHub', 'JUnit'].map((element, i) => (
                            <Grid key={i} item sx={{m: 1}}>
                                <Typography sx={{mx: 2, fontWeight: 700, border: 'solid 1px', p: 2, borderRadius: 2,  backgroundColor: '#eaaa'}}>
                                    {element}
                                </Typography>
                            </Grid>
                        ))
                    }

                </Grid>
            </Grid>


            <Grid data-aos="fade-up" item xs={12} md={6} lg={4} xl={3}  sx={{textAlign: 'center', color: 'white'}} display='flex' alignItems='center' flexDirection='column' justifyContent='center'>
                <Typography sx={{fontWeight: 800, textTransform: 'uppercase', fontSize: '1.2rem'}}>
                    Other Tools
                </Typography>
         
                <Grid container display='flex' justifyContent='center' sx={{textAlign: 'center', mt: 2}}>

                    {
                        ['Adobe (Photoshop, Premiere Pro)', 'Microsoft Office (Word, Excel, PowerPoint, Project)', 'Tableau', 'Power BI', 'Figma'].map((element, i) => (
                            <Grid key={i} item sx={{m: 1}}>
                                <Typography sx={{mx: 2, fontWeight: 700, border: 'solid 1px', p: 2, borderRadius: 2,  backgroundColor: '#aa321'}}>
                                    {element}
                                </Typography>
                            </Grid>
                        ))
                    }

                </Grid>
            </Grid>

        </Grid>
    )
}


import React from 'react'

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import GitHubIcon from '@mui/icons-material/GitHub';


let HackCompetition = (props) => {


    return (

        <Grid data-aos="fade-up" item xs={12} sm={10} md={6} lg={4} sx={{m: 2}}>
            <Card>
                <CardHeader
                    title={
                        <Typography variant="h5" sx={{ml: 1, p: 1, fontFamily: 'Anton, sans-serif'}}>
                            {props.title}
                        </Typography>
                    }
                />
                <CardMedia
                component='img'
                height='100%'
                src={props.image}
                style={{position: 'relative', minHeight: '100%', maxHeight: 350 }}
                alt="Image" 
            />
            <CardContent>
                <Typography variant="h6" sx={{ml: 1}}>
                    {props.description}
                </Typography>
                <br/>
                <Typography variant="body1" sx={{ml: 1}}>
                Submitted to <i><b>{props.competition}</b></i>
                </Typography>
            </CardContent>
            <CardActions>
                <Link href={props.github} target="_blank">
                    <Tooltip title={props.github} placement="bottom" arrow >
                        <GitHubIcon  sx={{ml: 2, mb: 1, color: 'black'}} fontSize={"large"} />  
                    </Tooltip>
                </Link>
                <Link href={props.site} target="_blank">
                    <Tooltip title={props.site} placement="bottom" arrow >
                        <OpenInBrowserIcon sx={{ml: 2, mb: 1, color: 'black'}} fontSize={"large"} />  
                    </Tooltip>
                </Link>
             
            </CardActions>
            </Card>
        </Grid>
    )
}

export default HackCompetition;
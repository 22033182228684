


import React, {Fragment} from 'react'

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import Carousel from 'react-material-ui-carousel'

let HackCarouselVideo = (props) => {
    

    return (

 
        <Grid data-aos="fade-up" item xs={12} sm={10} md={6} lg={4} sx={{m: 2}}>
            <Card>
                <CardHeader
                    title={
                        <Typography variant="h5" sx={{ml: 1, p: 1, fontFamily: 'Anton, sans-serif'}}>
                           {props.title}
                        </Typography>
                    }
                />

                <Carousel 
                className='centered'
                autoPlay={false}
                navButtonsAlwaysVisible={true}
              
                
            >

                <Fragment>
                    <CardMedia
                        component='img'
                        src={props.pics[0]}
                        alt="Image" 
                        sx={{textAlign: 'center', width: '100%', height: '100%', mt: 5, justifyContent: 'center', minHeight: 350 }}
                        />
                </Fragment>
                <Fragment>
                    <CardMedia
                        component='img'
                        src={props.pics[1]}
                        alt="Image" 
                        sx={{ textAlign: 'center', width: '50%', height: '100%', mx: 'auto', minHeight: 350}}
                        />
                </Fragment>
                <Fragment>
                    <CardMedia
                    component="iframe" 
                    image={props.pics[2]}
                    sx={{textAlign: 'center', width: '100%', height: 350, mt: 5, justifyContent: 'center', minHeight: 350}}
                    />
                </Fragment>
                    
            </Carousel>
            <CardContent>
                <Typography variant="h6" sx={{ml: 1}}>
                    {props.description}
                </Typography>
            </CardContent>

            </Card>
        </Grid>
    )
}

export default HackCarouselVideo;
import React, { useEffect, Fragment, useState, useRef } from 'react';
import '../App.css';
//import {gsap} from 'gsap';
import Aos from 'aos';
import 'aos/dist/aos.css'

import MailIcon from '@mui/icons-material/Mail';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';


import Ad2 from '../imgs/Ad2.png'
import Ad3 from '../imgs/Ad3.png'
import Calenda from '../imgs/calenda.png';
import socialApp from '../imgs/socialApp.png';
import traffic from '../imgs/traffic.png';
import pic1 from '../imgs/1.jpg';
import pic2 from '../imgs/2.jpg';
import pic3 from '../imgs/3.jpg';
import face from '../imgs/face.png'
import woof from '../imgs/woof.png'
import textbook from '../imgs/textbook.png'
import lynked from '../imgs/lynked.png'
import stars from '../imgs/stars.png'
import wordle from '../imgs/wordle.png'

import radish from '../imgs/radish.png'

import profilePic from '../imgs/profile-pic.png'

import Hack from './Hack';
import HackCompetition from './HackCompetition'
import HackCarousel from './HackCarousel';
import HackDes from './HackDes'
import HackText from './HackText';
import HackDesSite from './HackDesSite'
import HackCarouselVideo from './HackCarouselVideo';

import ScrollToTop from './ScrollToTop';
import { Avatar, Divider, ListItemButton, ListItemIcon, Drawer, Fab } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Education from './Education';
import Skills from './Skills';

import SchoolIcon from '@mui/icons-material/School';
import PersonIcon from '@mui/icons-material/Person';
import EngineeringIcon from '@mui/icons-material/Engineering';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';


// Hook
let useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
}


let Home = () => {

    let hacksRef =useRef()
    let projectRef = useRef()
    let aboutRef = useRef()
    let menuRef = useRef()
    let educationRef = useRef()
    let skillsRef = useRef()

    const [anchorElNav, setAnchorElNav] = useState(null);

    let ad1 = Ad2
    let ad2 = Ad3
    let video = "https://www.youtube.com/embed/Zn38lOO3e40"
    
    let picture1 = pic1
    let picture2 = pic2
    let picture3 = pic3
    
    let pics = [picture1, picture2, picture3]

    let imgs = [ad1, ad2, video]

    useEffect(()=> {
        Aos.init({duration: 2000})
    })

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    let goToSkills = () => {
        skillsRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start"
        });

        setTimeout(() => {
            handleCloseNavMenu()
        }, 1000)
    }

    let goToEducation = () => {
        educationRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start"
        });

        setTimeout(() => {
            handleCloseNavMenu()
        }, 1000)
    }

    let goToHacks = () => {
        hacksRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start"
        });
        
        setTimeout(() => {
            handleCloseNavMenu()
        }, 1000)
    }


    let goToProject = () => {

        projectRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start"
        })

        setTimeout(() => {
            handleCloseNavMenu()
        }, 1000)
      
    }

    
    let goToAbout = () => {
        
        aboutRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start"
        });
        
        setTimeout(() => {
            handleCloseNavMenu()
        }, 1000)
    }


    return (

        <Fragment>
            <div id="top"></div>

                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>

                    <Fab
                        size="large"
                        onClick={handleOpenNavMenu}
                        sx={{position: 'fixed', zIndex: 100, borderRadius: 10, border: 'solid 1px'}}
                    >
                        <MenuIcon/>
                    </Fab>
             
                    <Drawer
                        
                        anchor={'left'}
                        keepMounted
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                    >
                        <Toolbar sx={{backgroundColor: 'rgb(48, 48, 48)'}}/>
                        <Divider/>
                        <List sx={{width: 250, backgroundColor: 'rgb(48, 48, 48)', color: 'rgb(255, 204, 102)', height: '100vh'}}>

                            <ListItem  disablePadding sx={{my: 1}}>
                                <ListItemButton onClick={goToAbout} >
                                    <ListItemIcon>
                                        <PersonIcon fontSize='large' sx={{color: 'rgb(255, 204, 102)'}}/>
                                    </ListItemIcon>
                                    <ListItemText primary='About Me' />
                                </ListItemButton>
                            </ListItem>
                            <ListItem  disablePadding sx={{my: 1}}>
                                <ListItemButton onClick={goToEducation} >
                                    <ListItemIcon>
                                        <SchoolIcon fontSize='large' sx={{color: 'rgb(255, 204, 102)'}}/>
                                    </ListItemIcon>
                                    <ListItemText primary='Education' />
                                </ListItemButton>
                            </ListItem>
                            <ListItem  disablePadding sx={{my: 1}}>
                                <ListItemButton onClick={goToSkills} >
                                    <ListItemIcon>
                                        <EngineeringIcon fontSize='large' sx={{color: 'rgb(255, 204, 102)'}}/>
                                    </ListItemIcon>
                                    <ListItemText primary='Skills' />
                                </ListItemButton>
                            </ListItem>
                            <ListItem  disablePadding sx={{my: 1}}>
                                <ListItemButton onClick={goToHacks} >
                                    <ListItemIcon>
                                        <EnhancedEncryptionIcon fontSize='large' sx={{color: 'rgb(255, 204, 102)'}}/>
                                    </ListItemIcon>
                                    <ListItemText primary='Hackathons' />
                                </ListItemButton>
                            </ListItem>
                            <ListItem  disablePadding sx={{my: 1}}>
                                <ListItemButton onClick={goToProject} >
                                    <ListItemIcon>
                                        <FolderSharedIcon fontSize='large' sx={{color: 'rgb(255, 204, 102)'}}/>
                                    </ListItemIcon>
                                    <ListItemText primary='Projects' />
                                </ListItemButton>
                            </ListItem>
                        
                            <Divider/>
                                    
                                            
                        </List>                    
                    </Drawer>
                </Box>
   
            <AppBar ref={menuRef} position="static" sx={{backgroundColor: 'black', borderBottom: 'solid 1px white', zIndex: 10, display: { xs: 'none', md: 'flex' }}}>
                <Container>
                    <Toolbar sx={{alignItems: 'center', justifyContent: 'center', color: 'rgb(255, 204, 102)',}}>            
            
                        <Button sx={{color: 'rgb(255, 204, 102)', fontWeight: 700}}  onClick={goToAbout}>
                            About Me
                        </Button>
                        
                        <Button sx={{color: 'rgb(255, 204, 102)', fontWeight: 700}}  onClick={goToEducation}>
                           Education
                        </Button>

                        <Button sx={{color: 'rgb(255, 204, 102)', fontWeight: 700}}  onClick={goToSkills}>
                            Skills
                        </Button>

                        <Button sx={{color: 'rgb(255, 204, 102)', fontWeight: 700}}  onClick={goToHacks}>
                            Hackathons
                        </Button>

                        <Button sx={{color: 'rgb(255, 204, 102)', fontWeight: 700}}  onClick={goToProject}>
                            Projects
                        </Button>
                        
                    </Toolbar>
                </Container>
            </AppBar>


        <Grid container ref={aboutRef} id='about'>

            <Grid item xs={12} md={6} lg={7}>
            
                <Typography data-aos="fade-down" variant="h3" 
                    sx={{fontFamily: 'Anton, sans-serif', 
                        color: 'rgb(255, 204, 102)',
                        mx: 2,
                        pt: {xs: 12, md: 10, lg: 15},
                        mb: 3,
                        textAlign: 'center'
                    }}
                >
                    I am a driven aspiring software developer seeking to expand my skill set and bring innovative solutions to life.
                </Typography>
    
            </Grid>


            <Grid item xs={12} md={6} lg={5}>

                <Container data-aos="fade-left" className='intro' sx={{border: 'solid 1px', color: 'white', mb: 3, width: '100%', p: 5, textAlign: 'center'}}>
                    
                    <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                        <Avatar
                            sx={{width: 150, height: 150, borderRadius: 150}}
                            src={profilePic}
                        />
                        <Typography variant="h4" sx={{fontFamily: 'Anton, sans-serif', mt: 3, mb: 2}}>Mike Dimitrov</Typography>
                        <hr className='line' sx={{mt: 10}}/>
                    </Box>
                    
                    
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <List style={{textAlign: 'center'}}>

                            <ListItem key="email" >
                                <MailIcon color='inherit' sx={{mr: 1}}/>
                                <ListItemText >
                                    <Typography fontSize={'1.3rem'} fontWeight={700} >
                                        michail.dimitrov@mail.mcgill.ca
                                    </Typography>
                                </ListItemText>
                            </ListItem>

                        </List>

                        <Box display='flex'>

                            <Link href='https://github.com/mike1572' target="_blank">
                                <Tooltip title='https://github.com/mike1572' placement="bottom" arrow >
                                    <GitHubIcon sx={{mr: 1, mb: 1, color: 'white'}} fontSize="large" />  
                                </Tooltip>
                            </Link>

                            <Link href='https://www.linkedin.com/in/michail-dimitrov-03316b248/' target="_blank">
                                <Tooltip title='https://www.linkedin.com/in/michail-dimitrov-03316b248/' placement="bottom" arrow >
                                    <LinkedInIcon sx={{mr: 1, mb: 1, color: 'white'}} fontSize="large" />  
                                </Tooltip>
                            </Link>
                        </Box>


                    </Box>

                    <Box display='flex' justifyContent='center' sx={{textAlign: 'center', mt: 1}}>
                        <Typography sx={{mx: 2, fontWeight: 700}}>
                            French
                        </Typography>
                        <Typography sx={{mx: 2, fontWeight: 700}}>
                            English
                        </Typography>
                        <Typography sx={{mx: 2, fontWeight: 700}}>
                            Bulgarian
                        </Typography>
                    </Box>

                
                </Container>    
            </Grid>

        </Grid>

        <Divider sx={{borderBottom: 'solid white', mb: 3, mt: -3}}/>

        <Box ref={educationRef}>
            <Education />
        </Box>

        <Box ref={skillsRef}>
            <Skills />
        </Box>
       
        <Container id="hacks" data-aos="fade-right" ref={hacksRef} sx={{pt: 12, pb: 5}}>
            <Typography fontWeight={800} variant="h4" sx={{color: 'white', textAlign: 'center'}}>
                Hackathons and Competitions
            </Typography>
        </Container>
        
        <Grid container
            alignItems='flex-start'
            justifyContent='space-evenly'
            sx={{mt: 5}}
            spacing={2}
        >

            <Hack
                title="Rad Kitchen"
                image={radish}
                description="React Chrome extension designed for Radish's products website. By surfing and saving products of interests from the Radish website, the user can store them in a list. The extension will then generate a recipe based on these products and output an image of what the dish should look like. Winner of the Radish Sponsor Challenge and Overall Top 5 of the 115 projects presented."
                competition="McHacks 10"
                link="https://devpost.com/software/rad-kitchen"
                github="https://github.com/mike1572/recipe-chrome-extension"
            />

            <Hack 
                title="Word, My World"
                image={wordle}
                description="Web application helping you win every game of Wordle. Simply input the letters and get all combinations possible. Winner of Best Domain Name: most creative and original domain name (wordmyworld.tech) registered with domain.com"
                competition="PolyHacks - Hackatown 2022"
                link="https://devpost.com/software/project-name-todo"
                site="https://wordleresolver.web.app/"
                github="https://github.com/mike1572/polyhacksgenx"
            />

            <HackCompetition
                title="Lynked"
                image={lynked}
                description="Web application connecting entrepreneurs seeking funding to like minded investors."
                competition="BOLT McGill Winter 2022"
                github="https://github.com/mike1572/bolt"
                site="https://bolt-b9576.web.app/"
            />

            <Hack title="Woofmee" image={woof} 
                description="Web application allowing dog owners to connect and find playmates for their dogs."
                competition="ConUHacks VI"
                link="https://devpost.com/software/woofmee"
                site="https://woof-d3287.web.app/"
                github="https://github.com/mike1572/woof"
            />
            <Hack title="MoodChanger" image={face} 
                description="Web application which uses facial recognition to scan your facial expression and recommend you music found on youtube accordingly. Winner of the Best Newbie Hack category."
                competition="McHacks 9"
                link="https://devpost.com/software/moodchanger-b6m5si"
                site="https://face-a713b.web.app/"
                github="https://github.com/mike1572/face"
            />

            <Hack title="Textbook Exchange" image={textbook} 
                description="Web application allowing McGill students to find affordable textbooks for their classes."
                competition="Hack McWiCS 2022"
                link="https://devpost.com/software/textbook-exchange-nwg91s"
                site="https://elephant-f5f16.web.app/"
                github="https://github.com/tinamliang/elephantGroup"
            />

        </Grid>

        <Container id="hacks" data-aos="fade-right" ref={projectRef} sx={{pt: 12, pb: 5}}>
            <Typography fontWeight={800} variant="h4" sx={{color: 'white', textAlign: 'center'}}>
                Projects
            </Typography>
            <br/>
            <Typography fontWeight={700} variant="h5" sx={{color: 'white', textAlign: 'center'}}>
                Here are some technical and none technical projects I have been working on, either as part of a course or on my own.
            </Typography>
        </Container>

        <Grid container
            alignItems='flex-start'
            justifyContent='space-evenly'
            sx={{mt: 5}}
            spacing={2}
        >
            
            <HackCompetition
                title="Starstagram"
                image={stars}
                description="Web application with a purpose of displaying images fetched through Nasa's APIs"
                competition="None"
                github="https://github.com/mike1572/starstagram"
                site="https://starstagram-5a116.web.app/"
            />

            <HackCompetition
                title="Calenda"
                image={Calenda}
                description="The point of this system is to provide health professionals the flexibility needed to input specific working hours, reserve time slots for specific patients, and lock appointments for a number of days."
                competition="McGill Course INSY 333 Systems Analysis and Modeling"
                github="https://github.com/mike1572/Calenda"
                site="https://calendarapp-3ba29.web.app/"
            />



            <HackCompetition
                title="Social App"
                image={socialApp}
                description={
                    <Typography variant="h6">
                        Web application created following this <a style={{color:"black"}} href="https://www.youtube.com/watch?v=m_u6P5k0vP0&list=PL0PJYf5fFbFjp9dSHNJHkC7H9DqgCdL-X&index=2&t=16016s" rel="noreferrer" target="_blank">tutorial.</a>
                    </Typography>
                }
                competition="None"
                github="https://github.com/mike1572/SocialApp"
                site="https://social-app-9b5a8.web.app/"
            />

            <HackCarousel
                title="Quiz Capitals Of Europe"
                description="Mobile app testing the user's knowledge of European Capitals. Built with React Native."
                pics={pics}
                github="https://github.com/mike1572/QuizCapitalsEurope"
            />


            <HackCompetition
                title="Avoid the Traffic"
                image={traffic}
                description="Web browser game where the user needs to avoid the traffic and reach the hospital on time. Built with Phaser.js"
                competition="None"
                github="https://github.com/mike1572/AvoidTrafficGame"
                site="https://gametraffic-f4560.web.app/"
            />


            <Grid item xs={12} sm={10} md={6} lg={4} sx={{m: 2}}>
                <HackDes
                    title="Game Helpers"
                    description="Python programs helping you solve Crosswords and play Scrabble."
                    github="https://github.com/mike1572/gameHelpers"
                />

                    
                <HackDes
                    title="Sudoku Solver"
                    description="Java program done as part of course COMP 250 solving all 3x3 Sudokus."
                    github="https://github.com/mike1572/SudokuSolver"
                />

                <HackDesSite
                    title="Solar System in VR"
                    description="Built using A-Frame and HTML"
                    github="https://github.com/mike1572/solarSystem/"
                    site='https://solarsystem-1fa8f.web.app/'
                />
            </Grid>
          


            <HackCarouselVideo
                title="Advertising Campaign Project for Volkswagen"
                description="As part of course MRKT 453 Advertising and Media. The images and video are respectively made with Adobe Photoshop and Adobe Premiere Pro."
                pics={imgs}
            />

            <Grid item xs={12} sm={10} md={6} lg={4} sx={{m: 2}}>
                <HackText
                    title="The Berlin Wall: A Symbol of Division That Remains"
                    description="Research project on the Berlin Wall, its fall, and consequences in the modern era. Conducted as a pre-graduation project in Dawson College."
                    site="https://firebasestorage.googleapis.com/v0/b/portfolio-b1864.appspot.com/o/The_Berlin_Wall_A_Symbol_of_Division_That_Remains.pdf?alt=media"
                />

                <HackText
                    title="Tax Heavens"
                    description="Project conducted in French as the end of high school as part of the International Baccalaureate program."
                    site="https://firebasestorage.googleapis.com/v0/b/portfolio-b1864.appspot.com/o/Paradis%20Fiscaux.pdf?alt=media"
                />
            </Grid>

           


        </Grid>

        <Container sx={{mt: 7, mb: 3, pb: 3}}>
            <Typography className='fontColor' >
                Copyright © Mike Dimitrov {new Date().getFullYear()}
            </Typography>
        </Container>
        


        <ScrollToTop/>

    </Fragment>
  );
}

export default Home;



import Home from './components/Home'

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyC1gnF-VeHBTMa-INrjYE6ZqhA6ftISOx4",
  authDomain: "portfolio-b1864.firebaseapp.com",
  projectId: "portfolio-b1864",
  storageBucket: "portfolio-b1864.appspot.com",
  messagingSenderId: "400660121042",
  appId: "1:400660121042:web:72820cb012d283862ab40b",
  measurementId: "G-9TTTWFRH48"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {

  return (
    <Home/>
  );
}

export default App;

